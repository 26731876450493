<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <!-- <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i> <span> {{ this.$t('report.company_dashboard.reports') }}
              </span>
            </router-link>
          </li>
          <li>{{ this.$t('general.user_assignment _reports') }}</li>
        </ul> -->
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
                this.$t('report.company_dashboard.reports')
              }}
            </span>
          </li>
          <li> {{ $t('general.catalog_training_reports') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{$t('general.catalog_training_reports')}}</span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->

    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: red">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                     class="uk-search-input shadow-0 uk-form-small" type="search"/>
            </div>
          </div>

          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover btn-circle"
               href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'fullName')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'fullName')">{{ $t("sorting.z_a") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'created_at')">
                    {{ $t("sorting.created_at_old") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'created_at')">
                    {{ $t("sorting.created_at_new") }}</a>
                </li>
              </ul>
            </div>

            <!-- Filter -->
            <!-- <a class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a> -->
            <!-- <UserFilter @getFilter="getFilter($event)" :reportAssignment="true" :assignmentList="assignmentList">
            </UserFilter> -->
            <!-- <div id="filterDropdown" class="large"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
                  <div>
                    <h5 class="uk-text-bold mb-2">Atamalar</h5>
                    <multiselect v-model="assignment_id" :allow-empty="true" :multiple="true" :options="assignmentList"
                      :searchable="true" deselectLabel="" label="name" placeholder="Atama Seçiniz" selectLabel="Seçiniz"
                      tag-placeholder="Atama Seçiniz" track-by="id">
                      <template slot="option" slot-scope="option">
                        {{ option.option.name }}
                      </template>
</multiselect>
</div>
<div class="uk-flex uk-flex-right p-2">
  <button class="btn btn-default grey" @click="filterResult">
    <i class="uil-filter"></i> {{ $t("general.filter") }}
  </button>
</div>
</div>
</div>
</div> -->
            <a :uk-tooltip="$t('filters.export_excell')" class="btn btn-icon btn-hover btn-circle" href="javascript:;"
               @click="exportUserAssignment">
              <i v-if="!isReportDownloading" class="fa-file-excel fa"></i>
              <span v-if="isReportDownloading" uk-spinner="ratio: 0.8"></span>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t("general.username") }}</th>
              <th scope="col">{{ $t("users.reg_no") }}</th>
              <th scope="col">{{ $t("general.status") }}</th>
              <!-- <th scope="col">{{ $t("general.assignment_name") }}</th> -->
              <th scope="col">{{ $t("general.content_title") }}</th>
              <th scope="col">{{ $t("general.content_type") }}</th>
              <th scope="col">{{ $t("general.duration") }}(dk)</th>
              <th scope="col">{{ $t("general.start_date") }}</th>
              <!-- <th scope="col">{{ $t("general.end_date") }}</th> -->
              <th scope="col">{{ $t("general.content_point") }}</th>
              <th scope="col">{{ this.$t('general.completion_status') }}</th>
              <th scope="col">{{$t('excel.heading.completion_date')}}</th>
              <th scope="col">{{ $t("general.created_at") }}</th>
            </tr>
            </thead>

            <tbody class="list">
            <template v-for="item in formattedItems">
              <tr>
                <td>
                    <span>
                      {{ item.fullname }}</span>
                </td>
                <td>{{ item.registration_number }}</td>
                <td>{{ item.user_status }}</td>
                <!-- <td>{{ item.assignment_name }}</td> -->
                <td>{{ item.content_name }}</td>
                <td>{{ item.category }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.start_date }}</td>
                <!-- <td>{{ item.end_date }}</td> -->
                <td>{{ item.completed_status === 'Tamamlandı' ? item.point : '-' }}</td>
                <td v-html="item.completed_status"></td>
                <td>{{ item.completed_at }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment-timezone";
import axios from "axios";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";
import Multiselect from "vue-multiselect";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "SelfRegistiration",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: {
    Pagination,
    UserProfile,
    UserFilter,
    DefaultLoading,
    Multiselect,
  },
  data() {
    return {
      pagination: null,
      user_id: [],
      assignment_id: [],
      error: null,
      page: 1,
      per_page: 10,
      sort: "id",
      order: "desc",
      formattedItems: [],
      userList: [],
      assignmentList: [],
      isReportDownloading: false,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
      branch_id: '',
      company_id: '',
      department_id: '',
      region_id: '',
      title_id: '',
      position_id: '',
      ids: [],
      keepFilter: null
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    userStatus(statusId) {
      switch (statusId) {
        case 0:
          return "Pasif";
        case 1:
          return "Aktif";
      }
    },
    exportUserAssignment() {
      let self = this;
      //  if (+self.assignment_id.length === 0) {
      //     this.error = this.$t("validation.please_select_assignment");
      //     return false;
      //   }

      this.isReportDownloading = true;

      // let ids = [];
      // UIkit.dropdown("#filterDropdown").hide();
      // self.assignment_id.forEach((items) => {
      //   ids.push(items.id);
      // })
      let filters = {
        like: this.filter.search,
        page: this.page,
        per_page: this.per_page,
        sort: this.filter.sort,
        order: this.filter.order,
      };
      if (this.keepFilter) {
        let keepFilter = this.keepFilter;
        keepFilter.company_id ? filters['company_id'] = keepFilter.company_id : null;
        keepFilter.company_id = null;
        $.each(keepFilter, function (key, value) {
          if (value) {
            let ids = [];
            keepFilter[key]?.forEach((items) => {
              ids.push(items.id);
            });
            if (key === 'assignment_id') {
              keepFilter?.assignment_id?.every((assignmentId) => {
                if (assignmentId.id === null) {
                  ids = null
                  return false
                } else {
                  return true
                }
              })
            }
            filters[key] = JSON.stringify(ids)
          }
        });
      }
      axios
          .get(
              process.env.VUE_APP_BACKEND_APP_URL +
              "/api/report/export/user-trainings?sort=" +
              self.sort +
              "&order=" +
              self.order +
              (filters['branch_id'] ?
                  "&assignment_ids=" +
                  filters['assignment_id'] : '')
              // filters['branch_id'] ?
              //   "&branch_id=" +
              //   filters['branch_id'] : null,
              // filters['department_id'] ?
              //   "&department_id=" +
              //   filters['department_id'] : null,
              // filters['region_id'] ? "&region_id=" +
              //   filters['region_id'] : null,
              + (filters['branch_id'] ?
                  ("&branch_id=" + filters['branch_id']) : '')
              + (filters['department_id'] ?
                  ("&department_id=" + filters['department_id']) : '')
              + (filters['region_id'] ?
                  ("&region_id=" + filters['region_id']) : '')
              + (filters['title_id'] ?
                  "&title_id=" + filters['title_id'] : '')
              + (filters['position_id'] ?
                  ("&position_id=" + filters['position_id']) : '') + "&report=" + Math.random() * 9999999,
              {
                responseType: "blob",
                headers: this.apiHeaders,
              }
          )
          .then((response) => {
            let fileURL = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "user_assignment_report_list_" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.isReportDownloading = false;
          });
    },
    filterResult(filter = null) {
      this.error = null;
      if (filter) {
        this.keepFilter = filter;
        this.company_id = filter.company_id;
        this.branch_id = filter.branch_id;
        this.department_id = filter.department_id;
        this.region_id = filter.region_id;
        this.title_id = filter.title_id;
        this.position_id = filter.position_id;
        this.assignment_id = filter.assignment_id
      } else {
        filter = this.keepFilter;
      }
      let filters = {
        like: this.filter.search,
        page: this.page,
        per_page: this.per_page,
        sort: this.filter.sort,
        order: this.filter.order,
      };
      if (filter) {
        filter.company_id ? filters['company_id'] = filter.company_id : null;
        filter.company_id = null;
        $.each(filter, function (key, value) {
          if (value && Array.isArray(filter[key])) {
            let ids = [];
            filter[key]?.forEach((items) => {
              ids.push(items.id);
            });
            if (key === 'assignment_id') {
              filter?.assignment_id?.every((assignmentId) => {
                if (assignmentId.id === null) {
                  ids = null
                  return false
                } else {
                  return true
                }
              })
            }
            filters[key] = JSON.stringify(ids)
          }
        });
      }
      // UIkit.dropdown("#filterDropdown").hide();
      store
          .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
            url: "api/report/self-trainings",
            filters: filters,
          })
          .then((response) => {

            if (response.status) {
              let results = [];
              let responseData = response.data;
              this.pagination = responseData;

              responseData.data.forEach((item) => {
                let completedStatus = "Başlamadı";

                if (item.started_at) {
                  completedStatus = "Devam Ediyor";
                }

                if (item.completed_at) {
                  completedStatus = "Tamamlandı";
                }

                let startDate = item.contentStartDate
                    ? moment(item.contentStartDate)
                    : null;
                let endDate = item.end_data
                    ? moment(item.end_data)
                    : null;
                let userStatus = this.userStatus(item.status_id);
                let registrationNumber = item.registration_number || "-";
                results.push({
                  registration_number: registrationNumber,
                  user_status: userStatus,
                  // assignment_name: item.assignmentName,
                  content_name: item.training_name,
                  category: item.training_type_name,
                  fullname: item.fullName ?? item.fullname,
                  duration: Math.round(item.duration_as_seconds) || '-',
                  point: item.point,
                  start_date: item.started_at
                      ? moment(item.started_at).add(3, 'hours').format("DD/MM/YYYY HH:mm")
                      : "-",
                  // end_date: endDate ? endDate.format("DD/MM/YYYY") : "-",
                  completed_status: completedStatus,
                  completed_at: item.completed_at
                      ? moment(item.completed_at).add(3, 'hours').format("DD/MM/YYYY HH:mm")
                      : "-",
                  created_at: item.created_at
                      ? moment(item.created_at).add(3, 'hours').format("DD/MM/YYYY")
                      : "-",
                });
              });
              this.formattedItems = results;
            }
          });
    },
    getAssignments() {
      let self = this;
      let filters = {
        page: 0,
        sort: "id",
        order: "desc",
        per_page: this.per_page,
      };
      store
          .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
            url: "api/report/get-assignments",
            filters: filters,
          })
          .then((response) => {
            self.assignmentList = [
              {
                id: null,
                name: "Tümü",
              },
            ];
            if (response.status) {
              let results = [];
              let items = response.data;
              items.forEach((item) => {
                self.assignmentList.push({
                  id: item.id,
                  name: item.full_name,
                  order_number: item.order_number,
                });
              });
              //this.assignmentList = results;
            }
          });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;
      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getAssignments();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.page = 1;
      this.debouncedFilterSearch();
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}

.uk-tooltip {
  word-wrap: break-word;
  font-size: 10px !important;
}
</style>
